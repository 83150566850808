import React from "react"
import PropTypes from "prop-types"
import { Helmet } from "react-helmet"
import { JsonLd } from "../components/JsonLd"
import { graphql } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import Layout from "../components/layout"
import Directory from "../components/directory/directory"

const AboutUs = ({ aboutUs, meta, socialCard }) => {
  return (
    <>
      <Helmet
        title="About Us"
        titleTemplate={`%s | ${meta.title}`}
        meta={[
          {
            property: `og:type`,
            content: `article`,
          },
          {
            name: `description`,
            content: `The TIVP logo has its origins in a crop circle that appeared on June 4, 2018 in Sextony Dorset in the UK. The earth is in the center with...`,
          },
          {
            property: `og:url`,
            content: `${meta.url}/about-us`,
          },
          {
            property: `og:title`,
            content: `About Us | TIVP`,
          },
          {
            property: `og:description`,
            content: `The TIVP logo has its origins in a crop circle that appeared on June 4, 2018 in Sextony Dorset in the UK. The earth is in the center with...`,
          },
          {
            property: `article:author`,
            content: `TIVP`,
          },
          {
            name: `twitter:creator`,
            content: `@tivp_org`,
          },
          {
            name: `twitter:site`,
            content: `@tivp_org`,
          },
          {
            name: `twitter:title`,
            content: `About Us | TIVP`,
          },
          {
            name: `twitter:description`,
            content: `Our vision is educating people worldwide to gain new communication skills, understand cultural differences, and...`,
          },
          {
            name: "twitter:card",
            content: "summary_large_image",
          },
          {
            name: "twitter:image",
            content: `${meta.url}${socialCard.publicURL}`,
          },
          {
            property: "og:image",
            content: `${meta.url}${socialCard.publicURL}`,
          },
        ].concat(meta)}
      >
        <html lang="en" />
        <JsonLd>{{}}</JsonLd>
      </Helmet>
      <Layout>
        <div className="w-full flex flex-col justify-center items-center bg-white py-24">
          <div className="w-full px-4 tablet:px-6 laptop:w-1/2 desktop:w-1/2 4k:w-1/3 desktop:flex">
            <div className="w-full flex flex-col">
              <h5 className="text-center pb-2 text-stone-300">About Us</h5>
              <h1 className="text-stone-700 text-3xl tablet:text-4xl font-oswald font-semibold tracking-wide text-center">
                The Infinite Voice Project
              </h1>
              <div className="flex justify-center">
                <div className="w-full h-auto flex justify-center pb-6 desktop:px-0">
                  <div className="h-full w-full border border-gray-400">
                    <GatsbyImage
                      image={aboutUs.childImageSharp.gatsbyImageData}
                      className="aspect-w-16 aspect-h-9"
                      alt="TIVP logo"
                    />
                  </div>
                </div>
              </div>
              <div className="w-full flex flex-col desktop:flex-row">
                <div className="w-full desktop:px-0 pt-6 desktop:pt-0">
                  <div className="prose text-stone-700  font-roboto prose-green max-w-none selection:bg-green-200 ">
                    <h2>Our Mission & Vision</h2>
                    <p>
                      Our vision is educating people worldwide to gain new
                      communication skills, understand cultural differences, and
                      transcend the previously imposed limits of knowledge. Our
                      vision includes creating a safe space for experiencers to
                      connect with each other, to share, to heal, and to learn.
                    </p>
                    <p>
                      Our mission is to educate people worldwide regarding our
                      planet&#39;s Star Nation visitors, their many cultures,
                      and their various reasons for being on Earth. To foster a
                      right-minded approach of mutual respect, communication and
                      cooperation with our world&#39;s Star Nations through
                      education.
                    </p>
                    <h2>TIVP Logo Origins</h2>
                    <p>
                      The TIVP logo has its origins in a crop circle that
                      appeared on June 4, 2018 in Sextony Dorset in the UK. The
                      earth is in the center with rays of connection going out
                      to the protective circle of the seven Star Nations that
                      offer guidance, each represented by a sphere around the
                      outside. The resemblance to a wheel is apparent and
                      represents the seven Star Nations that have offered
                      “steering,” or guidance to the earth. The following
                      colored drawing outlines show one possible interpretation.
                      (There are likely many others!) The TIVP logo was a joint
                      creation between earth humans the P’nti and the 32 Star
                      Nation beings.
                    </p>
                    <h2>We&#39;re A Non-Profit</h2>
                    <p>
                      The Infinite Voice Project (TIVP) is a 501(c)3 non-profit.
                      Any donations made are tax deductible. We created TIVP for
                      educational purposes, to fill a need we see for
                      communication skills, cultural understanding, and
                      connection. We are starting this project with the website.
                      We already have plans to create an experiencer connection
                      area and then create and offer classes. If you would like
                      to make a donation, please click the donate button or
                      contact our CEO, Jeff Demmers at
                      <a
                        href="mailto:jeffd@theinfinitevoiceproject.org"
                        className="pl-1"
                      >
                        jeffd@theinfinitevoiceproject.org
                      </a>
                    </p>
                    <p>
                      Any support you offer is greatly appreciated and will help
                      move up our timetable.
                    </p>
                  </div>
                  <div className="flex justify-center">
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Directory />
      </Layout>
    </>
  )
}

const AboutUsPage = ({ data }) => {
  const aboutUsContent = data.file
  const socialCard = data.social
  const meta = data.site.siteMetadata
  if (!aboutUsContent) return null
  return <AboutUs aboutUs={aboutUsContent} meta={meta} socialCard={socialCard} />
}

export default AboutUsPage

AboutUs.propTypes = {
  aboutUs: PropTypes.object.isRequired,
  meta: PropTypes.object.isRequired,
}

export const query = graphql`
  query AboutUs {
    file(name: { eq: "TIVP-Logo-FeaturedPosts.jpg" }) {
      url
      childImageSharp {
        gatsbyImageData(quality: 100, layout: FULL_WIDTH, placeholder: BLURRED)
      }
    }
    social:file(name: {eq: "SocialCardTemplate-Free-Downloads"}) {
      id
      name
      publicURL
    }
    site {
      siteMetadata {
        title
        description
        author
      }
    }
  }
`
